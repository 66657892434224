<template>
  <el-dropdown trigger="click" ref="cityFilter">
    <button @click="toggleCity" :class="cityDropdownClass" class="btn filter-button" id="location-select-button"><span>{{cityName}}</span> <i @click.stop="removeCity" class="far fa-trash-alt"></i></button>
    <template #dropdown>
      <div class="el-dropdown-menu">
        <div class="location-select" >
          <input type="text" id="custom_autocomplete" placeholder="Ville" class="widget_city_suggestion" v-model="citySearch">
          <div class="city-filter-action">
            <a class="location-item" href="#" lgn="2.35183" lat="48.85658" @click="updateGeolocalisation">
              <div>
                <span class="city">Me localiser</span>
                <span class="region">Emplacement actuel</span>
              </div>
            </a>
          </div>

          <label class="suggestion-city-title">Suggestions</label>
          <a v-for="suggestion in suggestions" class="location-item" href="#" @click="selectLocation(suggestion)">
            <div>
              <span class="city">{{suggestion.city}}</span>
              <span class="region">{{suggestion.region}}, {{suggestion.country}}</span>
            </div>
          </a>
        </div>

      </div>
    </template>
  </el-dropdown>
</template>

<script>
import { reverseGeocoding, citySuggestions } from '../services/geocoding.js'
export default {
  name: "CityFilter",
  props: {
    modelValue: {
      default: [],
      type: [Array, String]
    }
  },
  data () {
    return {
      currentGeolocalisation: null,
      geolocalisationPlaces: [],
      selectedCity: null,
      toggleFilter: false,
      suggestionsLoaded: [],
      recentSuggestions: [],
      citySearch: '',
    }
  },
  watch: {
    citySearch: {
      handler (val) {
        if (val.length > 1) {
          this.resetGeolocalisation()
          this.searchCityName(val)
        }
      }
    },
    modelValue: {
      immediate: true,
      handler (v) {
        if (Array.isArray(v) && v.length === 2) {
          this.getCoordsInfos(v)
        } else {
          this.selectedCity = null
          this.currentGeolocalisation = null
        }
      }
    }
  },
  computed: {
    suggestions ()
    {
      const suggestionsType = this.currentGeolocalisation === null ? 'suggestionsLoaded' : 'geolocalisationPlaces'
      return this[suggestionsType].map((suggestion) => {
        const result = suggestion.place_name.split(', ')
          return {
            position: suggestion.geometry.coordinates,
            city: result[0],
            region: result[1],
            country: result[2]
          }
      })
    },
    cityName ()
    {
      return this.hasCitySelected ? this.selectedCity.city : 'Ville'
    },
    hasCitySelected ()
    {
      return this.selectedCity !== null;
    },
    valueDisplay () {
      return this.hasCitySelected ? this.cityName : 'Ville'
    },
    cityDropdownClass(){
      return this.hasCitySelected ? 'active' : ''
    }
  },
  methods: {
    resetGeolocalisation () {
      this.currentGeolocalisation = null
      this.geolocalisationPlaces = null
    },
    updateGeolocalisation () {
      this.geolocalisation()
      this.$emit('change', this.selectedCity)
    },
    geolocalisation ()
    {
      let options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };
      let that = this
      function success(pos) {
        let crd = pos.coords;
        // that.getCoordsInfos([crd.longitude, crd.latitude])
        that.currentGeolocalisation = [crd.longitude, crd.latitude];
        reverseGeocoding(that.currentGeolocalisation)
          .then(that.saveReverseGeocoding)
        // that.$refs.cityFilter.handleClose()
      }

      function error(err) {
        console.error(`ERREUR (${err.code}): ${err.message}`);
      }
      navigator.geolocation.getCurrentPosition(success, error, options);
    },
    selectLocation (location) {
      this.selectedCity = location
      this.$refs.cityFilter.handleClose()
      this.$emit('change', location)
    },
    handleError (e)
    {
      console.error(e)
    },
    saveCity (data, feature = 2)
    {
      if (data.query && data.features) {
        const result = data.features[feature].place_name.split(', ')
        let place = {
          position: data.features[feature].geometry.coordinates,
          city: result[0],
          region: result[1],
          country: result[2]
        }
        this.selectedCity = place
      }
    },
    saveReverseGeocoding (places) {
      this.geolocalisationPlaces = places.features
    },
    getCoordsInfos (coords)
    {
      reverseGeocoding(coords)
        .then(this.saveCity)
        .catch(this.handleError)
    },
    saveGeoSuggestions (data)
    {
      const suggestions = data.features.map((suggestion) => {
        const result = suggestion.place_name.split(', ')
        return {
          position: suggestion.geometry.coordinates,
          city: result[0],
          region: result[1],
          country: result[2]
        }
      })
      this.geolocalisationPlaces = [...suggestions]
    },
    saveSuggestion (data)
    {
      this.suggestionsLoaded = [...data.features]
    },
    searchCityName (city) {
      citySuggestions(city)
        .then(this.saveSuggestion)
        .catch(this.handleError)
    },
    removeCity () {
      this.selectedCity = null
      this.$emit('change', this.selectedCity)
    },
    toggleCity () {
      // this.toggleFilter = this.toggleFilter === 'city' ? false : 'city'
    }
  }
}
</script>

<style lang="scss">
.city-filter-action {
  margin: 10px 0px 10px 0px;
}
.location-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  .city {
    font-size: 16px;
  }
  .region {
    display: block;
    font-weight: 400;
    white-space: nowrap;
    text-align: inherit;
    font-size: 12px;
    color: #6F6F6F;
  }
  &::before {
    display: block;
    margin-right: 6px;
    content: '';
    width: 24px;
    height: 24px;
    background: url("/img/new/icons/location-outline.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    background: #FFEDF0;
    color: #fc2249;
    &::before {
      background: url("/img/new/icons/location-outline-active.svg");
    }
    .region {
      color: #fc2249;
    }
  }
}
</style>
